@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;1,200;1,400;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  --zoom: 0.8;
  zoom: var(--zoom);
  -moz-transform: scale(var(--zoom));
  -moz-transform-origin: 0 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker-wrapper input {
  background-color: #f7f7f7 !important;
}

.datepicker-error .react-datepicker-wrapper {
  border: 1px solid red;
}

.react-datepicker-wrapper {
  background-color: #f7f7f7 !important;
  padding: 10px !important;
  border-radius: 0.5rem;
}

.dark .react-datepicker__triangle::after {
  border-top-color: #1b1b1b !important;
}

.dark
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #2f7512 !important;
}

.dark .react-datepicker {
  border: none;
}

.date-picker-remove-bg > .react-datepicker-wrapper {
  background-color: transparent !important;
}
.date-picker-remove-bg > .react-datepicker-wrapper input {
  background-color: transparent !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.react-datepicker__day--selected {
  background-color: #97c940 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #97c940 !important;
}

/* react-datepicker__day react-datepicker__day--011 react-datepicker__day--keyboard-selected react-datepicker__day--today */

table {
  table-layout: auto;
}

/* td:first-child {
  width: 1%;
  white-space: nowrap;
} */

.dark .react-datepicker__day:hover {
  background: black;
}

.react-datepicker__header {
  background-color: #568d26 !important;
  color: white !important;
}

.dark .react-datepicker__header {
  background-color: #2f7512 !important;
}

.dark .react-datepicker__month-container {
  background-color: #1b1b1b !important;
  color: white;
}

.dark .react-datepicker__day {
  color: white;
}

.react-datepicker__year-select {
  background-color: #568d26;
}

.react-datepicker__header > * {
  color: white !important;
}

.react-datepicker__day-names > * {
  color: white !important;
}

.react-datepicker__year-dropdown-container--select select {
  padding: 0 10px;
}

.react-datepicker {
  box-shadow: 1px 10px 10px rgba(0, 0, 0, 0.2) !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.react-datepicker__close-icon::after {
  background-color: #568d26 !important;
}

.datepickerTransparent > * {
  background-color: transparent !important;
}
.datepickerTransparent input {
  background-color: transparent !important;
}

.picker-statistics > div:first-child {
  margin: 0;
  background: white;
  border-radius: 1.5rem;
}

.dark .picker-statistics > div:first-child {
  background: #1b1b1b;
  border: 1px solid white;
}

.\!text-white .edit-pipelines {
  border: 1px solid white;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-datepicker__close-icon::after {
  font-size: 13px !important;
}
.dark nav .active,
.dark .dropdownPointer::after {
  background-color: #1b1b1b;
}

.dark .dropdownPointer::after {
  border: 1px solid #4a4848;
}

.dark .menu,
.dark .side-modal {
  box-shadow: 0 0 9px #7b7f8485;
}

body.dark {
  background-color: #1b1b1b;
}

.dark .dark-shadow-card {
  box-shadow: 0 0 9px #7b7f8485 !important;
}

.overflow-unset {
  overflow: unset;
  overflow-x: unset !important;
}
